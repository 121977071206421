import React, {useState} from "react";
import {getAgentHelloMessage} from "./AgentHelloMessage";
import {normalizeDateTime, transformToMessageTime, transformURLToLinkInText} from '../../utils/transformers'
import {PrescriptionMessage} from "./PrescriptionMessage";
import {ImmunizationMessage} from "./ImmunizationMessage";
import {NurseAdviceMessage} from "./NurseAdviceMessage";
import {CustomerInfoMessage} from "./CustomerInfoMessage";
import {AgentTransferNoteMessage} from "./AgentTransferNoteMessage";
import {CustomerAttributesMessage} from "./CustomerAttributesMessage";
import {GLPMessage} from "./GLPMessage";

const Message = (props) => {
    const {contact, message, showTime} = props;

    let content = message.Content;
    let prescription = message.prescriptionRefillRequest;
    let immunization = message.immunizationRequest;
    let nurseAdvice = message.nurseAdviceRequest;
    let glp = message.glpRequest;
    let agentTransferNote = message.agentTransferNote || {};

    let role = 'system';
    switch (message.ParticipantRole) {
        case 'AGENT':
            role =  'sender';
        break;
        case 'CUSTOMER':
            role = 'receiver';
        break;
        default:
            role = 'system';
        break;
    }

    let messageJson;
    if (message.DisplayName === 'BOT' || message.DisplayName === 'SYSTEM_MESSAGE') {
        try {
            messageJson = JSON.parse(message.Content);
            content = messageJson.title;
        }
        catch (e) {}
    }

    if (content === 'START_CHAT') {
        content = 'Start Chat';
    }
    else if (message.Type === 'EVENT' && message.ContentType === 'application/vnd.amazonaws.connect.event.chat.ended') {
        content = 'Chat ended';
    }
    else if (message.Type === 'EVENT' && message.ParticipantRole === 'AGENT' && message.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined') {
        content = getAgentHelloMessage({
            contact: contact,
            message: message,
            prescription: prescription,
            immunization: immunization,
            showTransferMessage: props.showTransferMessage,
        });
    }
    else if (messageJson && messageJson.type === 'EVENT') {
        try {
            if (!message.referralInfoShown
                && messageJson.additionalData
                && !messageJson.customerName
                && !messageJson.customerDOB
            ) {
                content = '';
                role = 'receiver'
                const {mrn, department, ref, token, queue} = messageJson.additionalData;
                if (mrn) {
                    content += 'Patient MRN: ' + mrn + ' <br/>';
                }
                if (department) {
                    content += 'Appointment department: ' + department + ' <br/>';
                }
                if (ref) {
                    content += 'Patient referral number: ' + ref + ' <br/> ';
                }
                if (token) {
                    props.setToken(token)
                    if (props.currentContact) {
                        const {
                            patientMrn,
                            patientFullName,
                            referralTeam,
                            referralExtId
                        } = JSON.parse(props.currentContact?.attributes?.additionalData.value)
                        if (patientMrn) {
                            content += 'Patient MRN: ' + patientMrn + ' ' + token + ' <br/>' + "\n";
                        }
                        if (queue) {
                            content += 'Appointment department: ' + queue + ' <br/>';
                        }
                        if (patientFullName) {
                            content += 'Patient referral full name: ' + patientFullName + ' <br/> ';
                        }
                        if (referralTeam) {
                            content += 'Patient referral team: ' + referralTeam + ' <br/> ';
                        }
                        if (referralExtId) {
                            content += 'Patient referral extended id: ' + referralExtId + ' <br/> ';
                        }
                    }
                }
            }
        } catch (e) {}
    }

    if (!content && Object.keys(agentTransferNote).length === 0) {
        return '';
    }

    if (content) {
        content = (Array.isArray(content)) ? content.join('<br/><br/>').toString() : content.toString();
        if (content.includes('[!]')) {
            content = content.replace('[!]', '<br/><br/>');
        }
        //Replace new line characters with line breaks
        if (content.includes('\n')) {
            content = content.replace(/\n/g, '<br>');
        }
        // check Back action
        if (content.indexOf('sys_back:') === 0) {
            const sysBack = content.split(':');
            content = '(Go Back) ' + sysBack[2];
        }

        content = transformURLToLinkInText(content); // wrap URLs with <a/> tag
    }

    const messageTime = transformToMessageTime(normalizeDateTime(message.AbsoluteTime));

    return (<>
            { content && (
                role === 'receiver' ?
                <div className={'row message-left'}>
                    <div className="col-md-9">
                        <p dangerouslySetInnerHTML={{__html: content}}/>
                        <span className={'message-time ' + (showTime ? '' : 'd-none')}>
                                {messageTime}
                            </span>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                :
                <div className={'row message-right'}>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-9 text-right">
                        <p dangerouslySetInnerHTML={{__html: content}}/>
                        <span className={'message-time ' + (showTime ? '' : 'd-none')}>
                                {messageTime}
                            </span>
                    </div>
                </div>
            )}
            {
                message.customerInfo
                    && !prescription
                    && !immunization
                    && !nurseAdvice ? <CustomerInfoMessage data={message.customerInfo} messageTime={messageTime} /> : ''
            }
            {
                message.getContactDataAttributes ?
                    <CustomerAttributesMessage getContactDataAttributes={message?.getContactDataAttributes} messageTime={messageTime} /> : ''
            }
            {
                prescription ? <PrescriptionMessage data={prescription} messageTime={messageTime} /> : ''
            }
            {
                immunization ? <ImmunizationMessage data={immunization} messageTime={messageTime} /> : ''
            }
            {
                nurseAdvice ? <NurseAdviceMessage data={nurseAdvice} messageTime={messageTime} /> : ''
            }
            {
                glp ? <GLPMessage data={glp} messageTime={messageTime} /> : ''
            }
            {
                agentTransferNote ? <AgentTransferNoteMessage note={agentTransferNote} messageTime={messageTime} /> : ''
            }
        </>
    );
};

export default Message;
