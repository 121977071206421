import React from "react";
import {
    QUEUE_NAME_AHS,
    QUEUE_NAME_BALLAD_CUSTOMER_SERVICE, QUEUE_NAME_BASIC,
    QUEUE_NAME_BH, QUEUE_NAME_BILLING, QUEUE_NAME_BILLING_ES,
    QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE,
    QUEUE_NAME_BSMH_BS_ORDERS,
    QUEUE_NAME_BSMH_ESCALATIONS,
    QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE,
    QUEUE_NAME_BSMH_MH_ORDERS, QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE, QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE,
    QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE, QUEUE_NAME_FLOYD_RADIOLOGY, QUEUE_NAME_MAYO,
    QUEUE_NAME_NCH_CUSTOMER_SERVICE,
    QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE,
    QUEUE_NAME_ROPER_CUSTOMER_SERVICE,
    QUEUE_NAME_SCRIPPS_1_800,
    QUEUE_NAME_SCRIPPS_ESTIMATES
} from "../../utils/constant";
import {i18n} from "../../utils/helpers";

export const getAgentHelloMessage = (params) =>
{
    const {contact, message, prescription, immunization, showTransferMessage} = params;
    const agentName = message.DisplayName;
    let queueName;
    let customerName;
    try {
        queueName = contact.connection.getQueue().name;
        customerName = contact.attributes.customerName.value;
    } catch (e) {}

    let result = 'Hi, my name is {agentName}, how can I help you?';

    switch (queueName) {
        case QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE:
        case QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE:
        case QUEUE_NAME_BSMH_ESCALATIONS:
        case QUEUE_NAME_BSMH_BS_ORDERS:
        case QUEUE_NAME_BSMH_MH_ORDERS:
            result = "My name is {agentName}, I may need to ask you a few questions " +
                "in order for me to better assist you!";
            break;
        case QUEUE_NAME_SCRIPPS_1_800:
            result = 'Hello, my name is {agentName}. I am a Health Resource Coordinator at Scripps Health. ' +
                'How can I help you today?';
            break;
        case QUEUE_NAME_SCRIPPS_ESTIMATES:
            result = 'Hi, my name is {agentName}. I am a Patient Service Specialist ' +
                'on the Estimate team at Scripps Health. How can I help you?';
            break;
        case QUEUE_NAME_NCH_CUSTOMER_SERVICE:
        case QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE:
        case QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE:
        case QUEUE_NAME_BALLAD_CUSTOMER_SERVICE:
        case QUEUE_NAME_ROPER_CUSTOMER_SERVICE:
        case QUEUE_NAME_AHS:
        case QUEUE_NAME_BH:
        case QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE:
        case QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE:
            result = "My name is {agentName}, I may need to ask you a few questions in order for me to better assist you!";
            break;
        case QUEUE_NAME_MAYO:
            result = "You’re now connected with {agentName}";
            break;
        case QUEUE_NAME_FLOYD_RADIOLOGY:
            result = "My name is {agentName}. Can I please have the patient’s name and date of birth?";
            break;
    }

    if (message.usedQueueName === QUEUE_NAME_BILLING_ES) {
        i18n.setLocale('es')
    } else {
        i18n.setLocale('en')
    }

    if (message.extMessages?.agentHelloMessage) {
        result = message.extMessages.agentHelloMessage;
    }

    // Prescription Refill Request
    if (prescription?.additionalData?.prescription) {
        result = 'Hi, my name is {agentName}. Please allow me 2 minutes to review this request.';
        if (Array.isArray(prescription.additionalData.prescription)
            && prescription.additionalData.prescription.length
        ) {
            result = result + ' I will confirm when it is complete. If you disconnect before the request is confirmed it will be cancelled.';
        }
    }

    // Immunization Record Request
    if (immunization?.additionalData?.immunization) {
        result = 'Hi, my name is {agentName}. Please allow me 2 minutes to review this request. ' +
            'I will confirm when it is complete. If you disconnect before the request is confirmed it will be cancelled.';
    }

    if (showTransferMessage) {
        result = i18n.__('Hi, my name is %s! Give me one moment to review your request.', agentName)

        if (message.extMessages?.transferMessage) {
            result = message.extMessages.transferMessage;
        }
    }

    if ([QUEUE_NAME_BILLING, QUEUE_NAME_BILLING_ES].includes(message.usedQueueName)) {
        result = i18n.__('Hi, my name is %s, can I please have the patient’s Date of Birth or Responsible Person Number?', agentName);
    }


    return result.replace("{customerName}", customerName).replace("{agentName}", agentName);
}
